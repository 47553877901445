<template>
    <footer class="footer m-0 p-0 mt-3" style="position: unset;">

        <div id="block-followblock" class="follow-us settings-tray-editable block block-cbd-utility block-follow-block"
            data-drupal-settingstray="editable">


            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-3 d-flex align-items-center">
                        <div class="h2 text-white text-uppercase m-0">Follow us</div>
                    </div>
                    <div class="col-12 col-lg-9">
                        <div class="nav flex-follow-us">
                            <a href="http://www.youtube.com/user/chmcbd" title="YouTube">
                                <img src="https://www.cbd.int/themes/custom/bootstrap_sass/images/someround_youtube_neg.svg" alt="YouTube"
                                    loading="lazy">
                            </a>
                            <a href="http://www.facebook.com/UNBiodiversity" title="Facebook">
                                <img src="https://www.cbd.int/themes/custom/bootstrap_sass/images/someround_facebook_neg.svg"
                                    alt="Facebook" loading="lazy">
                            </a>
                            <a href="https://twitter.com/unbiodiversity" title="Twitter">
                                <img src="https://www.cbd.int/themes/custom/bootstrap_sass/images/someround_twitter_neg.svg" alt="Twitter"
                                    loading="lazy">
                            </a>
                            <a href="https://www.linkedin.com/company/unbiodiversity" title="LinkedIn">
                                <img src="https://www.cbd.int/themes/custom/bootstrap_sass/images/someround_linkedin_neg.svg"
                                    alt="LinkedIn" loading="lazy">
                            </a>
                            <a href="https://instagram.com/unbiodiversity" title="Instagram">
                                <img src="https://www.cbd.int/themes/custom/bootstrap_sass/images/someround_instagram_neg.svg"
                                    alt="Instagram" loading="lazy">
                            </a>
                            <a href="https://www.cbd.int/rss" title="RSS">
                                <img src="https://www.cbd.int/themes/custom/bootstrap_sass/images/someround_feed_neg.svg" alt="RSS"
                                    loading="lazy">
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>




        <div class="cbd-footer">
            <div class="container">
                <div class="row">
                    <div id="block-quicklinksblock"
                        class="footer-block footer-margin col-12 col-lg-5 settings-tray-editable block block-cbd-utility block-quick-links-block"
                        data-drupal-settingstray="editable">


                        <h5 class="h4 text-uppercase">quick links</h5>
                        <hr>
                        <div class="footer-links two-column">
                            <a href="https://absch.cbd.int/" title="Access and Benefit-Sharing Clearing-House">Access
                                and Benefit-Sharing Clearing-House</a>
                            <a href="https://bch.cbd.int/" title="Biosafety Clearing-House">Biosafety Clearing-House</a>
                            <a href="https://chm.cbd.int/" title="Clearing-House Mechanism">Clearing-House Mechanism</a>
                            <a href="https://www.cbd.int/contributions" title="Contributions">Contributions</a>
                            <a href="https://www.cbd.int/countries/" title="Country Profiles">Country Profiles</a>
                            <a href="https://www.cbd.int/meetings/" title="Meetings and Events">Meetings and Events</a>
                            <a href="https://www.cbd.int/nbsap" title="National Biodiversity Strategies and Action Plans (NBSAPs)">National
                                Biodiversity Strategies and Action Plans (NBSAPs)</a>
                            <a href="https://www.cbd.int/nfp" title="National Focal Points">National Focal Points</a>
                            <a href="https://www.cbd.int/reports" title="National Reports">National Reports</a>
                            <a href="https://www.cbd.int/news/" title="News Headlines">News Headlines</a>
                            <a href="https://www.cbd.int/notifications/" title="Notifications">Notifications</a>
                            <a href="https://www.cbd.int/parties" title="Parties">Parties</a>
                            <a href="https://www.cbd.int/publications" title="Publications">Publications</a>
                        </div>

                    </div>


                    <div id="block-topicsfooterblock"
                        class="footer-block footer-margin col-12 col-lg-3 settings-tray-editable block block-cbd-utility block-topics-footer-block"
                        data-drupal-settingstray="editable">


                        <h5 class="h4 text-uppercase">Topics</h5>
                        <hr>
                        <div class="footer-links">
                            <a href="https://www.cbd.int/topic/business-women-youth-and-other-stakeholders"
                                title="Business, Women, Youth and other Stakeholders">Business, Women, Youth and other
                                Stakeholders</a>
                            <a href="https://www.cbd.int/topic/climate-change" title="Climate Change">Climate Change</a>
                            <a href="https://www.cbd.int/topic/poverty-reduction" title="Poverty Reduction">Poverty Reduction</a>
                            <a href="https://www.cbd.int/topic/food" title="Food">Food</a>
                            <a href="https://www.cbd.int/topic/water" title="Water">Water</a>
                            <a href="https://www.cbd.int/topic/indigenous-peoples-and-local-communities"
                                title="Indigenous Peoples and Local Communities">Indigenous Peoples and Local
                                Communities</a>
                        </div>

                    </div>



                    <div class="desktop-only footer-block footer-margin col-6 col-lg-2">
                        <h5 class="h4 text-uppercase">Information</h5>
                        <hr>
                        <div class="flex-one-column">
                            <a href="https://www.cbd.int/contact" title="Contact us">Contact us</a>
                            <a href="https://www.cbd.int/terms" title="Terms of Use">Terms of Use</a>
                            <a href="https://www.cbd.int/privacy" title="Privacy Policy">Privacy Policy</a>
                            <a href="https://www.cbd.int/credits" title="Credits">Credits</a>
                            <a href="https://www.cbd.int/copyright" title="© CBD Secretariat">© CBD Secretariat</a>
                        </div>
                    </div>
                    <div class="desktop-only footer-block footer-margin col-6 col-lg-2">
                        <h5 class="h4 text-uppercase">A part of</h5>
                        <hr>
                        <img class="uno-brand" src="https://www.cbd.int/themes/custom/bootstrap_sass/images/UN_brand_neg.svg" alt="UN logo"
                            loading="lazy">
                        <img class="uno-environment"
                            src="https://www.cbd.int/themes/custom/bootstrap_sass/images/UNEP_2019_brand_white.svg"
                            alt="UN Environment Programme logo" loading="lazy">
                    </div>

                    <div class="mobile-only footer-block footer-margin col-12">
                        <div class="row">
                            <div class="col-6">
                                <div class="footer-headline">INFORMATION</div>
                            </div>
                            <div class="col-6">
                                <div class="footer-headline">A PART OF</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class=" col-12">
                                <hr>
                            </div>
                        </div>
                        <div class="row">
                            <div class="footer-block col-6 ">
                                <div class="flex-one-column">
                                    <a href="https://www.cbd.int/contact" title="Contact us">Contact us</a>
                                    <a href="https://www.cbd.int/terms" title="Terms of Use">Terms of Use</a>
                                    <a href="https://www.cbd.int/privacy" title="Privacy Policy">Privacy Policy</a>
                                    <a href="https://www.cbd.int/credits" title="Credits">Credits</a>
                                    <a href="https://www.cbd.int/copyright" title="© CBD Secretariat">© CBD Secretariat</a>
                                </div>
                            </div>
                            <div class="footer-block col-6 ">
                                <img class="uno-brand" src="https://www.cbd.int/themes/custom/bootstrap_sass/images/UN_brand_neg.svg"
                                    alt="UN logo" loading="lazy">
                                <img class="uno-environment"
                                    src="https://www.cbd.int/themes/custom/bootstrap_sass/images/UNEP_2019_brand_white.svg"
                                    alt="UN Environment Programme logo" loading="lazy">
                            </div>
                        </div>
                    </div>




                </div>
            </div>
        </div>


    </footer>
</template>
<script>

</script>

<style scoped src="./cbd.css"></style>
<style scoped>
    .footer .footer-block a{
        font-size: 1em!important;
    }
    .footer .h4{
        font-size: 1em!important;
    }
</style>